import { useRouter } from "next/router";
import { useEffect } from "react";

import { useWindowName } from "./useWindowName";

export function useBroadcastLogout() {
  const router = useRouter();
  const { updateWindowName } = useWindowName();

  useEffect(() => {
    const channel = new BroadcastChannel("auth-channel");

    channel.onmessage = event => {
      if (event.data === "logout") {
        updateWindowName();
        router.push("/login");
      }
    };

    return () => {
      channel.close();
    };
  }, [router]);
}
