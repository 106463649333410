import MessageTokenExpired from "@src/components/messages/token-expired";
import Modal from "@src/components/modal";
import { useGetUserReportsQuery } from "@src/services/reports";
import { loginActions } from "@src/store/slices/login";
import {
  selectAuthExpireError,
  selectAuthRedirectToLogin,
} from "@src/store/slices/login/selectors";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useEffect } from "react";
import React from "react";

import { useBroadcastLogout } from "@src/hooks/useBroadcastLogout";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
const Menu = dynamic(() => import("../Menu"), { ssr: false });

export default function Layout({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { data } = useGetUserReportsQuery({});
  const { reports, externalWorkspaceId } = data || { reports: [] };
  const error = useAppSelector(selectAuthExpireError);
  const redirectToLogin = useAppSelector(selectAuthRedirectToLogin);

  useBroadcastLogout();

  useEffect(() => {
    if (redirectToLogin) {
      router.push("/login");
    } else if (router.asPath === "/") {
      router.push("/home");
    }

    return () => {
      dispatch(loginActions.controlView({ redirectToLogin: false }));
    };
  }, [dispatch, redirectToLogin, router]);

  useEffect(() => {
    const { slugs } = router.query;
    if (router.asPath !== "/" && !slugs) return;

    const [workspaceId, reportId] = Array.isArray(slugs) ? slugs : [];

    const [report] = reports || [];

    if (!workspaceId && !reportId) {
      if (!report) return;
      const { externalId } = report;
      router.push(`/${externalWorkspaceId}/${externalId}`);
    }
  }, [router, router.query, reports, externalWorkspaceId]);

  return (
    <section className={`flex flex-col justify-between w-full min-h-full bg-body`}>
      <Menu reports={reports} workspaceId={externalWorkspaceId} />
      <div className="overflow-auto ml-[88px]">
        {React.cloneElement(children as React.ReactElement<any>, { reports })}
      </div>
      {error ? (
        <Modal isCloseIcon={false} width="w-[400px]" height="h-[588px]">
          <MessageTokenExpired />
        </Modal>
      ) : null}
    </section>
  );
}
